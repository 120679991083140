<template>
    <div class="body">
        <div class="particles-container" id="particles-js">
            <canvas class="particles-js-canvas-el" width="1920" height="1080" style="width: 100%; height: 100%;"></canvas>
        </div>
        <div class="login-container">
            <div class="loginModule">
                <el-form class="login-form" :model="Form" :rules="rules" ref="Form" :hide-required-asterisk="true">
                    <h2>用户登录</h2>
                    <el-form-item label="用户名/手机号" prop="uname">
                        <el-input v-model="Form.uname" placeholder="请输入用户名/手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="pwd">
                        <el-input type="password" v-model="Form.pwd" placeholder="请输入密码"></el-input>
                    </el-form-item>
                    <el-form-item label="验证码" prop="code">
                        <div class="flex width-all-100">
                            <el-input class="width-120" v-model="Form.code" placeholder="请输入验证码"></el-input>
                            <el-image style="width:100px;height:40px;margin-left:10px" :src="url" lazy></el-image>
                            <div class="font-size-10 color-409eff cursor-pointer margin-left-10" @click="VerificationPicture">看不清换一张
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="loginbutton" @click="logInClick">登录</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import * as loginApi from '@/request/login';
export default {
    name: 'login',
    data() {
        return {
            url: '', //验证码
            Form: {
                uname: "",
                pwd: "",
                code: "",
            },
            rules: {
                uname: [
                    { required: true, message: '请输入用户名/手机号', trigger: 'blur' },
                ],
                pwd: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ],
                code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                ],
            },
        };
    },
    mounted() {
        this.VerificationPicture();
        particlesJS('particles-js', {
                particles: {
                    number: { 
                        value: 50,  // 减少粒子数量
                        density: {
                            enable: true,
                            value_area: 800
                        }
                    },
                    color: { 
                        value: ['#3498db', '#e74c3c', '#00ff7f', '#ffeb3b']
                    },
                    shape: { 
                        type: 'circle'
                    },
                    opacity: { 
                        value: 0.6,
                        random: true,
                        animation: {
                            enable: true,
                            speed: 1,
                            opacity_min: 0.1,
                            sync: false
                        }
                    },
                    size: { 
                        value: 3,
                        random: true
                    },
                    line_linked: {
                        enable: true,
                        distance: 150,
                        color: '#3498db',
                        opacity: 0.2,
                        width: 1
                    },
                    move: {
                        enable: true,
                        speed: 1.5,  // 降低移动速度
                        direction: 'none',
                        random: true,
                        straight: false,
                        out_mode: 'out',
                        bounce: false
                    }
                },
                interactivity: {
                    detect_on: 'canvas',
                    events: {
                        onhover: {
                            enable: true,
                            mode: 'grab'
                        },
                        onclick: {
                            enable: true,
                            mode: 'push'
                        },
                        resize: true
                    },
                    modes: {
                        grab: {
                            distance: 140,
                            line_linked: {
                                opacity: 0.5
                            }
                        }
                    }
                }
            });
    },
    methods: {
        // 获取验证码
        VerificationPicture() {
            loginApi.getYzm().then(res => {
                this.url = window.URL.createObjectURL(new Blob([res], { type: 'image/png' }));
            })
        },
        // 登录
        logInClick() {
            this.$refs.Form.validate((valid) => {
                if (valid) {
                    const params = {
                        uname: this.Form.uname,
                        pwd: this.Form.pwd,
                        code: this.Form.code,
                    }
                    loginApi.login(params).then(res => {
                        sessionStorage.setItem("userinfo", JSON.stringify(res.data))
                        if (res.code === "0") {
                            this.$message({
                                message: `登录${res.message}`,
                                type: 'success'
                            });
                            this.$router.push("/index")
                        } else if (res.code === "0005") {
                            this.Form.code = "";
                            this.$message.error(res.message);
                            this.VerificationPicture()
                        } else if (res.code === "0002") {
                            this.$message.error(res.message);
                        }
                    })
                }
            })
        }
    }
}

</script>
<style lang='scss' scoped>
@import "../assets/style/styles.css";
// .headline {
//     padding: 20px 40px;
//     height: 20px;
//     line-height: 20px;
//     color: #CD3E21;
// }

// .main {
//     width: 100%;
//     height: calc(100vh - 60px);
//     background-image: url(../assets/login_page.png);
//     background-size: contain;
//     position: absolute;
//     top: 60px;
//     left: 0;
//     background-position: center 0;
//     background-repeat: no-repeat;
//     background-attachment: fixed;
//     background-size: cover;
//     -webkit-background-size: cover;
//     /* 兼容Webkit内核浏览器如Chrome和Safari */
//     -o-background-size: cover;
//     /* 兼容Opera */
//     zoom: 1;
// }

// .loginModule {
//     width: 440px;
//     height: 564px;
//     padding: 70px 80px;
//     box-sizing: border-box;
//     position: absolute;
//     top: 220px;
//     right: 221px;
//     background: #fff;
// }

// .subheading {
//     margin-bottom: 4px;
//     color: #85909f;
//     font-weight: 400;
//     font-size: 16px;
// }

// .title {
//     font-size: 30px;
//     font-weight: 600;
//     color: #323a44;
//     margin-bottom: 20px;
// }

// .logIn {
//     margin-top: 80px;
//     width: 280px;
//     height: 48px;
//     background-color: #CD3E21;
//     color: #fff;
// } 


::v-deep .el-form-item__label {
    color: #DDDDDD;
    font-size: 12px;
    line-height: 20px
}

::v-deep .el-input__inner {
    border: none;
    border-bottom: 1px solid #E6E6E6;
    line-height: 30px;
    background: transparent !important;
    color: #fff;
}
</style>