<template>
    <div class="width-all-100 height-all-100">
        <div class="height-36 margin-bottom-20 text-align-right">
            <el-button type="primary" plain @click="addModel">新增模型</el-button>
        </div>
        <div class="grid width-all-100 height-all-100minus25" v-loading="loading">
            <div v-for="(item, index) in modeldata" :key="index"
                class="border-radius-10 padding-all-10 background-f7f9fd position-relative cursor-pointer height-220"
                @click="clickModeldata(item, index)" :class="clickIndex === index ? 'border-624aff' : ''">
                <!-- 删除 -->
                <i class="el-icon-delete position-absolute right-10 color-409eff" @click.stop="DeleteModel(item)"></i>
                <!-- 修改 -->
                <i class="el-icon-edit position-absolute right-40 color-CD3E21" @click.stop="EditModel(item)"></i>
                    <div class="font-weight-600 font-size-28 color-27254c margin-bottom-5 width-500 singlelineEllipsis">模型名称：
                        <el-tooltip :disabled="item.modelName.length > 12 ? false : true" class="item" effect="dark" :content ="item.modelName" placement="top" open-delay="200">
                            <span>{{ item.modelName }}</span>
                        </el-tooltip>
                    </div>
                <div class="font-weight-500 font-size-16 color-9a9cb6 margin-bottom-5">框架名称：{{ item.frameName }}</div>
                <div class="font-size-14">数据集名称：{{ item.datasourceName || "--" }}</div>
                <!-- <div class="font-size-14">数据集描述：{{ item. datasourceDesc}}</div> -->
                <div class="font-size-14 margin-bottom-5">数据集：{{  stringInterception(item.datasource,37) || "--" }}</div>
                <el-tooltip :disabled="item.modelDesc.length > 30 ? false : true" class="item" effect="dark" :content ="item.modelDesc" placement="top" open-delay="200">
                    <div class="font-size-14 margin-bottom-5 height-40 ellipsis">模型描述：{{ item.modelDesc }}</div>
                </el-tooltip>
                <div class="font-size-14 margin-bottom-5">模型文件：{{ stringInterception(item.modelFile,37) || "--" }}</div>
                <div class="flex flex-wrap-wrap font-size-12 margin-bottom-5">
                    <div class="margin-right-20">mAP50：{{ item.map50 }}</div>
                    <div class="margin-right-20">mAP50_95：{{ item.map5095 }}</div>
                    <div class="margin-right-20">召回率:<span>{{ item.recall }}</span></div>
                    <div class="margin-right-20">准确率:<span>{{ item.accuracy }}</span></div>
                    <div class="margin-right-20">训练轮数:<span>{{ item.trainNum }}</span></div>
                </div>
                <div class="flex flex-wrap-wrap font-size-12">
                    <div class="margin-right-20">创建人：<span>{{ item.userName }}</span></div>
                    <div class="margin-right-10"><span>{{ item.createTime }}</span>上传</div>
                </div>
            </div>
        </div>
        <div class="position-absolute bottom-15 right-15">
            <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="pageSize"
            layout="total, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
        <!-- 新增编辑弹窗 -->
        <el-dialog :title="dialog.title" :visible="dialog.open" :close-on-click-modal="false" width="800px" @close="cancel" :destroy-on-close="true">
            <el-form :model="form" label-width="120px" :inline="true">
                <el-form-item label="模型名称">
                    <el-input v-model="form.modelName" placeholder="请输入模型名称"></el-input>
                    <div class="font-siz-10 color-c0c4cc line-height-18 max-width-240">
                        例如：Yolov5、Yolov8、Resnet.Vgg...
                    </div>
                </el-form-item>
                <el-form-item label="框架名称" placeholder="请输入框架名称">
                    <el-input v-model="form.frameName"></el-input>
                    <div class="font-siz-10 color-c0c4cc line-height-18 max-width-240">
                        例如：pytorch、TensorFlow、Keras
                    </div>
                </el-form-item>
                <el-form-item label="是否改进">
                    <el-radio-group v-model="form.isImprove">
                        <el-radio :label="0">否</el-radio>
                        <el-radio :label="1">是</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="改进描述" v-if="form.isImprove === 1">
                    <el-input class="width-240 height-80" type="textarea" v-model="form.improveDesc" placeholder="请输入框架名称"></el-input>
                </el-form-item>
                <el-form-item label="数据集名称">
                    <el-input v-model="form.datasourceName"></el-input>
                </el-form-item>
                <el-form-item class="height-80" label="数据集描述">
                    <el-input class="width-240 height-80" type="textarea" v-model="form.datasourceDesc"></el-input>
                </el-form-item>
                <el-form-item class="height-80" label="数据集">
                    <!-- <el-input v-model="form.datasource"></el-input> -->
                    <el-upload class="upload-demo" action="/webapi/ModelRecords/upload"
                        :on-success="datasourceSuccess" :on-error="datasourceError" :before-upload="beforeUpload">
                        <el-button size="small" type="primary">上传数据集合</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item class="height-80" label="模型描述">
                    <el-input class="width-240 height-80" type="textarea" v-model="form.modelDesc"></el-input>
                </el-form-item>
                <el-form-item class="height-80" label="模型文件">
                    <el-upload class="upload-demo" action="/webapi/ModelRecords/upload"
                        :on-success="modelFileSuccess" :on-error="modelFileError" :before-upload="beforeUpload">
                        <el-button size="small" type="primary">上传模型文件</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="mAP50">
                    <el-input-number :controls="false" v-model="form.map50"></el-input-number>
                    <div class="font-siz-10 color-c0c4cc line-height-18">
                        一个小数，例如：0.9999
                    </div>
                </el-form-item>
                <el-form-item label="mAP50_95">
                    <el-input-number :controls="false" v-model="form.map5095"></el-input-number>
                    <div class="font-siz-10 color-c0c4cc line-height-18">
                        一个小数，例如：0.9999
                    </div>
                </el-form-item>
                <el-form-item label="召回率">
                    <el-input-number :controls="false" v-model="form.recall"></el-input-number>
                    <div class="font-siz-10 color-c0c4cc line-height-18">
                        一个小数，例如：0.9999
                    </div>
                </el-form-item>
                <el-form-item label="准确率">
                    <el-input-number :controls="false" v-model="form.accuracy"></el-input-number>
                    <div class="font-siz-10 color-c0c4cc line-height-18">
                        一个小数，例如：0.9999
                    </div>
                </el-form-item>
                <el-form-item label="模型训练轮数">
                    <el-input-number :controls="false" v-model="form.trainNum"></el-input-number>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitBtn">{{
                    dialog.submitName
                }}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as moodelDataApi from "@/request/modelData";
export default {
    name: "",
    data() {
        return {
            modeldata: [], // 模型数据
            loading: false,
            total: 0,
            page: 1, //页码
            pageSize: 9, // 条数
            dialog: {
                open: false,
                title: "新增模型", // 弹窗名称
                submitName: "新增", // 弹窗按钮
            },
            form: {
                modelName:"",
                frameName:"",
                isImprove: 0,
                improveDesc:"",
                datasourceName:"",
                datasourceDesc:"",
                datasource:"",
                map50:null,
                map5095:null,
                recall:null,
                accuracy:null,
                modelDesc:"",
                modelFile:"",
                trainNum:null,
            },
            clickIndex: -1,
        };
    },

    components: {},

    computed: {},
    created() {
        if(this.$route.query.page) {
            const query = this.$route.query
            this.clickIndex =  Number(query.index);
            this.page = Number(query.page);
            this.getMoodelData();
            return
        }
    },
    mounted() {
        this.getMoodelData();
    },
    methods: {
        stringInterception(item,index){
            const data = item.slice(index);
            return data;
        },
        // 模型数据
        getMoodelData() {
            const params = {
                pageSize: this.pageSize,
                page: this.page,
            };
            this.loading =true;
            // const list = [
            //     {
            //         id: 2,
            //         modelName: "ceshi",
            //         frameName: "ceshi",
            //         isImprove: 1,
            //         improveDesc: "ceshideshujv",
            //         datasourceName: "cehsi",
            //         datasourceDesc: "ceshi",
            //         datasource: "ceshi",
            //         map50: "ceshi",
            //         map5095: "ceshi",
            //         recall: "12345",
            //         accuracy: "1234567890",
            //         modelDesc: "8765678o9876578976789",
            //         modelFile: "ceshi",
            //         createTime: "2024-12-05 14:55:35",
            //         updateTime: "2024-12-05 14:55:35",
            //         createBy: 1,
            //         updateBy: 1,
            //         trainNum: 100,
            //     },
            //     {
            //         id: 2,
            //         modelName: "ceshi",
            //         frameName: "ceshi",
            //         isImprove: 1,
            //         improveDesc: "ceshideshujv",
            //         datasourceName: "cehsi",
            //         datasourceDesc: "ceshi",
            //         datasource: "ceshi",
            //         map50: "ceshi",
            //         map5095: "ceshi",
            //         recall: "12345",
            //         accuracy: "1234567890",
            //         modelDesc: "8765678o9876578976789",
            //         modelFile: "ceshi",
            //         createTime: "2024-12-05 14:55:35",
            //         updateTime: "2024-12-05 14:55:35",
            //         createBy: 1,
            //         updateBy: 1,
            //         trainNum: 100,
            //     },
            //     {
            //         id: 2,
            //         modelName: "ceshi",
            //         frameName: "ceshi",
            //         isImprove: 1,
            //         improveDesc: "ceshideshujv",
            //         datasourceName: "cehsi",
            //         datasourceDesc: "ceshi",
            //         datasource: "ceshi",
            //         map50: "ceshi",
            //         map5095: "ceshi",
            //         recall: "12345",
            //         accuracy: "1234567890",
            //         modelDesc: "8765678o9876578976789",
            //         modelFile: "ceshi",
            //         createTime: "2024-12-05 14:55:35",
            //         updateTime: "2024-12-05 14:55:35",
            //         createBy: 1,
            //         updateBy: 1,
            //         trainNum: 100,
            //     },
            //     {
            //         id: 2,
            //         modelName: "ceshi",
            //         frameName: "ceshi",
            //         isImprove: 1,
            //         improveDesc: "ceshideshujv",
            //         datasourceName: "cehsi",
            //         datasourceDesc: "ceshi",
            //         datasource: "ceshi",
            //         map50: "ceshi",
            //         map5095: "ceshi",
            //         recall: "12345",
            //         accuracy: "1234567890",
            //         modelDesc: "8765678o9876578976789",
            //         modelFile: "ceshi",
            //         createTime: "2024-12-05 14:55:35",
            //         updateTime: "2024-12-05 14:55:35",
            //         createBy: 1,
            //         updateBy: 1,
            //         trainNum: 100,
            //     },
            //     {
            //         id: 2,
            //         modelName: "ceshi",
            //         frameName: "ceshi",
            //         isImprove: 1,
            //         improveDesc: "ceshideshujv",
            //         datasourceName: "cehsi",
            //         datasourceDesc: "ceshi",
            //         datasource: "ceshi",
            //         map50: "ceshi",
            //         map5095: "ceshi",
            //         recall: "12345",
            //         accuracy: "1234567890",
            //         modelDesc: "8765678o9876578976789",
            //         modelFile: "ceshi",
            //         createTime: "2024-12-05 14:55:35",
            //         updateTime: "2024-12-05 14:55:35",
            //         createBy: 1,
            //         updateBy: 1,
            //         trainNum: 100,
            //     },
            //     {
            //         id: 2,
            //         modelName: "ceshi",
            //         frameName: "ceshi",
            //         isImprove: 1,
            //         improveDesc: "ceshideshujv",
            //         datasourceName: "cehsi",
            //         datasourceDesc: "ceshi",
            //         datasource: "ceshi",
            //         map50: "ceshi",
            //         map5095: "ceshi",
            //         recall: "12345",
            //         accuracy: "1234567890",
            //         modelDesc: "8765678o9876578976789",
            //         modelFile: "ceshi",
            //         createTime: "2024-12-05 14:55:35",
            //         updateTime: "2024-12-05 14:55:35",
            //         createBy: 1,
            //         updateBy: 1,
            //         trainNum: 100,
            //     },
            //     {
            //         id: 2,
            //         modelName: "ceshi",
            //         frameName: "ceshi",
            //         isImprove: 1,
            //         improveDesc: "ceshideshujv",
            //         datasourceName: "cehsi",
            //         datasourceDesc: "ceshi",
            //         datasource: "ceshi",
            //         map50: "ceshi",
            //         map5095: "ceshi",
            //         recall: "12345",
            //         accuracy: "1234567890",
            //         modelDesc: "8765678o9876578976789",
            //         modelFile: "ceshi",
            //         createTime: "2024-12-05 14:55:35",
            //         updateTime: "2024-12-05 14:55:35",
            //         createBy: 1,
            //         updateBy: 1,
            //         trainNum: 100,
            //     },
            //     {
            //         id: 2,
            //         modelName: "ceshi",
            //         frameName: "ceshi",
            //         isImprove: 1,
            //         improveDesc: "ceshideshujv",
            //         datasourceName: "cehsi",
            //         datasourceDesc: "ceshi",
            //         datasource: "ceshi",
            //         map50: "ceshi",
            //         map5095: "ceshi",
            //         recall: "12345",
            //         accuracy: "1234567890",
            //         modelDesc: "8765678o9876578976789",
            //         modelFile: "ceshi",
            //         createTime: "2024-12-05 14:55:35",
            //         updateTime: "2024-12-05 14:55:35",
            //         createBy: 1,
            //         updateBy: 1,
            //         trainNum: 100,
            //     },
            //     {
            //         id: 2,
            //         modelName: "ceshi",
            //         frameName: "ceshi",
            //         isImprove: 1,
            //         improveDesc: "ceshideshujv",
            //         datasourceName: "cehsi",
            //         datasourceDesc: "ceshi",
            //         datasource: "ceshi",
            //         map50: "ceshi",
            //         map5095: "ceshi",
            //         recall: "12345",
            //         accuracy: "1234567890",
            //         modelDesc: "8765678o9876578976789",
            //         modelFile: "ceshi",
            //         createTime: "2024-12-05 14:55:35",
            //         updateTime: "2024-12-05 14:55:35",
            //         createBy: 1,
            //         updateBy: 1,
            //         trainNum: 100,
            //     },
            // ];
            moodelDataApi
                .MoodelData(params)
                .then((res) => {
                    if (res.code === "0") {
                        this.loading = false;
                        this.modeldata = res.data;
                        this.total= res.dataCount;
                    }
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        // 删除模型
        DeleteModel(item) {
            this.$confirm(`是否要删除${item.modelName}模型`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(() => {
                moodelDataApi.ModelDelete(item.id).then((res) => {
                    if (res.code === "0") {
                        this.$message({
                            type: "success",
                            message: "删除成功!",
                        });
                        this.getMoodelData();
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            }).catch(() => { });;
        },
        // 分页
        handleCurrentChange(val) {
            this.page = val;
            this.getMoodelData();
        },
        addModel() {
            this.dialog.open = true;
            this.dialog.title = "新增模型"
            this.dialog.submitName = "新增"
        },
        EditModel(item){
            this.dialog.open = true;
            this.dialog.title = "修改模型"
            this.dialog.submitName = "修改"
            this.form = {
                id:item.id,
                modelName:item.modelName,
                frameName:item.frameName,
                isImprove: item.isImprove,
                improveDesc:item.improveDesc,
                datasourceName:item.datasourceName,
                datasourceDesc:item.datasourceDesc,
                datasource:item.datasourceDesc,
                map50:item.map50,
                map5095:item.map5095,
                recall:item.recall,
                accuracy:item.accuracy,
                modelDesc:item.modelDesc,
                modelFile:item.modelFile,
                trainNum:item.trainNum,
            };
        },
        // 取消-关闭弹窗
        cancel(){
            this.dialog.open = false;
            this.form = {
                modelName:"",
                frameName:"",
                isImprove: 0,
                improveDesc:"",
                datasourceName:"",
                datasourceDesc:"",
                datasource:"",
                map50:null,
                map5095:null,
                recall:null,
                accuracy:null,
                modelDesc:"",
                modelFile:"",
                trainNum:null,
            };
        },
        // 新增或者修改
        submitBtn() {
            if(this.dialog.title === "新增模型") {
                moodelDataApi.ModelAdd(this.form).then((res) => {
                    if (res.code === "0") {
                        this.cancel()
                        this.getMoodelData(); 
                    }
                }).catch(()=>{
                    this.dialog.open = true;
                });
            } else {
                moodelDataApi.ModelUpdate(this.form).then((res) => {
                    if (res.code === "0") {
                        this.cancel()
                        this.getMoodelData(); 
                    }
                }).catch(()=>{
                    this.dialog.open = true;
                });
            }
        },
        // 点击
        clickModeldata(item, index) {
            this.$router.push({
                path:"/model/details",
                query:{
                    id:item.id,
                    page:this.page,
                    index:index,
                }
            });
        },
        // 上传限制
        beforeUpload(file){
            console.log('[ file ] >', file)
            const isLt500M =file.size / 1024 / 1024 < 500;
            console.log('[ islt500M ] >', isLt500M);
            if(!isLt500M) {
                this.$message.error("上传文件大小不能超过 500MB!");
            }
        },
        // 数据集上传成功
        datasourceSuccess(response){
            if (response.code === "0") {
                this.form.datasource = response.data;
                this.$message({
                        type: "success",
                        message: "上传成功!",
                    });
            }
        },
        // 数据集上传失败
        datasourceError(res){
            this.$message.error('上传失败，请重新上传');
        },
        // 模型上传
        modelFileSuccess(response){
            if (response.code === "0") {
                this.form.modelFile = response.data;
                this.$message({
                        type: "success",
                        message: "上传成功!",
                    });
            }
        },
         // 模型上传失败
        modelFileError(res){
            this.$message.error('上传失败，请重新上传');
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header {
    padding: 20px;
    border-bottom: 1px solid #e5e6eb;
}

::v-deep .el-dialog__footer {
    padding: 20px;
    border-top: 1px solid #e5e6eb;
}

::v-deep .el-dialog__title {
    font-size: 24px;
}

::v-deep .el-form-item {
    width: 360px;
    margin-bottom: 10px;
}

::v-deep .el-form-item .el-input__inner {
    height: 36px;
    line-height: 36px;
}

::v-deep .el-form-item__label {
    font-size: 16px;
}
::v-deep .el-textarea__inner{
    min-height: 80px !important;
}

::v-deep .el-upload-list{
    width: 240px;
}
</style>
